.slider-image-container {
  width: 100%;
  height: 738px;
  overflow: hidden;
}

.slider-image-container .slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.slick-prev {
  left: 25px !important;
  z-index: 4 !important;
}

.slick-next {
  right: 70px !important;
  z-index: 4 !important;
}

.slick-prev,
.slick-next {
  transform: translate(-50%, -50%) !important;
}

.slick-prev::before,
.slick-next::before {
  font-size: 50px !important;
}
