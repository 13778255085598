/*@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');*/

/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*    font-family: 'Poppins', sans-serif;*/
/*}*/

/*body {*/
/*    overflow: hidden;*/
/*    background: #f2f2f2;*/
/*}*/

#click {
    display: none;
}

.chat-label {
    position: absolute;
    right: 30px;
    bottom: 20px;
    height: 55px;
    width: 55px;
    background: -webkit-linear-gradient(left, #ed5129, #ed5129);
    text-align: center;
    line-height: 55px;
    border-radius: 50px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

.chat-label i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease;
}

.chat-label i.fas {
    opacity: 0;
    pointer-events: none;
}

#click:checked ~ .chat-label i.fas {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) rotate(180deg);
}

#click:checked ~ .chat-label i.fab {
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -50%) rotate(180deg);
}

.chat-wrapper {
    position: absolute;
    right: 30px;
    max-width: 400px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.chat-wrapper .head-text {
    line-height: 60px;
    color: #fff;
    border-radius: 15px 15px 0 0;
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    background: -webkit-linear-gradient(left, #ed5129, #ed5129);
}

.chat-wrapper .chat-box {
    padding: 20px;
    width: 100%;
}

.chat-box .desc-text {
    color: #515365;
    text-align: center;
    line-height: 25px;
    font-size: 17px;
    font-weight: 500;
}

.chat-box .chat-form {
    padding: 10px 15px;
    margin: 20px 0;
    border-radius: 25px;
    border: 1px solid lightgrey;
}

.chat-box .chat-form .chat-field {
    height: 50px;
    width: 100%;
    margin-top: 20px;
}

.chat-box .chat-form .chat-field:last-child {
    margin-bottom: 15px;
}

.chat-form .chat-field input,
.chat-form .chat-field button,
.chat-form .textarea textarea {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    border: 1px solid lightgrey;
    outline: none;
    border-radius: 25px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.chat-form .chat-field input:focus,
.chat-form .textarea textarea:focus {
    border-color: #ed5129;
}

.chat-form .chat-field input::placeholder,
.chat-form .textarea textarea::placeholder {
    color: silver;
    transition: all 0.3s ease;
}

.chat-form .chat-field input:focus::placeholder,
.chat-form .textarea textarea:focus::placeholder {
    color: lightgrey;
}

.chat-box .chat-form .textarea {
    height: 70px;
    width: 100%;
}

.chat-box .chat-form .textarea textarea {
    height: 100%;
    border-radius: 50px;
    resize: none;
    padding: 15px 20px;
    font-size: 16px;
}

.chat-box .chat-form .chat-field button {
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    background: -webkit-linear-gradient(left, #ed5129, #ed5129);
    transition: all 0.3s ease;
}

.chat-box .chat-form .chat-field button:active {
    transform: scale(0.97);
}

.avatar-item {
    margin-right: 24px;
}

[class*="-col-rtl"] .avatar-item {
    margin-right: 0;
    margin-left: 24px;
}
