@font-face {
  font-family: "Rajdhani";
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&display=swap")
    format("truetype");
}

#banner2 .carousel-item:after {
  background: none repeat scroll 0 0 !important;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.main_bg {
  border-radius: 10px;
  border: 0px !important;
  min-width: 100%;
  background: #ed5129 none repeat scroll 0 0;
  fill: #ed5129;
  padding-bottom: 0 !important;
}

#navigation_bar {
  background: white none repeat scroll 0 0 !important;
  color: black;
}

.navbar-expand-lg .navbar-nav > li > a {
  color: black !important;
  display: block;
  font-size: 13px;
  font-weight: 800;
  line-height: 27px;
  padding: 20px 22px;
  text-transform: uppercase;
  position: relative;
}

.sttyl {
  color: #ef6744;
  font-size: 30px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 800 !important;
  letter-spacing: -1px;
}

.stlspor {
  color: #232628;
  font-size: 34px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}

.about_info .icon_box {
  border-color: #ef6744 !important;
  color: #ef6744 !important;
  fill: #ef6744 !important;
}

.about_info {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2) !important;
}

.section-header {
  padding-bottom: 1% !important;
}

.sttylsv {
  color: #ef6744;
  font-size: 28px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}

.stlsporcxv {
  color: #232628;
  font-family: Montserrat;
  font-size: 28px !important;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}

.abourdesc {
  text-align: left;
  font-size: 15px;
  color: #888888;
  font-family: Open Sans;
}

.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.facts_section_bg {
  background: rgba(0, 0, 0, 0)
    url("/images/pexels-norma-mortenson-43914811.jpg") no-repeat scroll center
    center / cover;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.section-padding {
  padding: 4% 0 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  border-radius: 3px !important;
}

.ant-input {
  height: 42px !important;
  border-radius: 3px !important;
  font-size: 15px !important;
  color: #888888 !important;
}

.ant-select {
  font-size: 15px !important;
  color: #888888 !important;
}

/*
.ant-tooltip {

    font-size: 10px !important;
    visibility: hidden;
    z-index: 1 !important;
}

.ant-tooltip-inner {

    background-color: white !important;
    color: #ef6744 !important;
} */

.widget_heading i {
  color: #ef6744 !important;
}

.btn.btn-block.fd {
  background-color: #ed5129 !important;
}

.bg-inc-g {
  background-color: #ed5129 !important;
}

.img-fluid {
  max-width: 100%;
  height: 150px !important;
}

.img-fluidD {
  max-width: 100%;
  height: 230px !important;
  width: 370px !important;
  object-fit: cover;
  height: 100% !important;
  max-height: 237px;
}

.secondary-bg {
  background-color: white !important;
  color: #ed5129 !important;
}

.bwt-color {
  color: #ed5129 !important;
  cursor: pointer;
}

.bwt-color:hover {
  color: white !important;
  cursor: pointer;
  background-color: #ed5129 !important;
}

.img-fluid-VB {
  max-width: 100%;
  height: 250px;
}

.about_info h5 {
  margin: 0 auto 8% !important;
  height: 55px !important;
}

.page-header-finic {
  background-image: url(https://kifal-auto.ma/assets/back-vender.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0 none;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.sttylFinincetxt {
  color: #ef6744;
  font-size: 20px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -1px;
}

.map-dirc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 2rem;
  width: 90%;
}

.Pdvcontainter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.datamapc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  width: 30%;
}

.abourdescMap {
  text-align: left;
  font-size: 14px;
  color: #888888;
  font-family: Open Sans;
}

.sttylsvMap {
  color: #ef6744;
  font-size: 15px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}

.stlsporMap {
  color: #232628;
  font-size: 14px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}

.sttylMap {
  color: #ef6744;
  font-size: 15px !important;
  font-family: Montserrat;
  line-height: 1.2;
  font-weight: 700;
}

.CarDetail_car-detail__3qqA_ {
  padding: 3.125rem 2.1875rem;
  background-color: #fafafa;
}

.CarDetail_car-detail__infos__33x40 {
  padding-bottom: 2.5rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.08);
  margin-bottom: 1.875rem;
}

.CarDetail_car-detail__name__SvoDt {
  position: relative;
  padding-left: 0.75rem;
  line-height: 1.2;
  margin-bottom: 1.875rem;
}

.CarDetail_car-detail__name__model__337CP {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-size: 26px;
  /* font-size: 2.125rem; */
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: inherit;
  font-weight: 600;
}

.CarDetail_car-detail__name__version__2W20H {
  font-size: 22px;
  font-size: 1.375rem;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: inherit;
}

.CarDetail_car-detail__name__id-offre__m_6c0 {
  margin-top: 0.125rem;
  font-size: 16px;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: inherit;
}

.CarDetail_car-detail__price__c1fs- {
  font-size: 28px;
  /* font-size: 2.625rem; */
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  color: #ef6744;
  display: inline-block;
}

.CarDetail_car-detail__adresse__2Bpuy {
  padding: 0 2.1875rem;
  margin-bottom: 1.875rem;
  line-height: 1.2;
}

.CarDetail_car-detail__adresse__2Bpuy h3 {
  font-family: "Renault Life Bold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue",
    "Helvetica", "Arial", "sans-serif";
  font-size: 24px;
  font-size: 1.5rem;
  color: #313131;
}

.CarDetail_car-detail__adresse__2Bpuy p {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-size: 16px;
  /* font-size: 1.25rem; */
  color: #747474;
  text-transform: capitalize;
}

.SimTitle {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-size: 20px;
  /* font-size: 1.25rem; */
  color: #747474;
  text-transform: capitalize;
}

.CarDetail_car-detail__price__c1fs- sub {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-size: 18px;
  /* font-size: 2rem; */
  /* vertical-align: text-bottom; */
}

.CarDetail_car-detail__adresse__2Bpuy {
  padding: 0 2.1875rem;
  margin-bottom: 1.875rem;
  line-height: 1.2;
}

.CarDetail_car-detail__adresse__2Bpuy h3 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-size: 20px;
  /* font-size: 1.5rem; */
  color: #313131;
}

.CarDetail_car-detail__ctas__1RC-8 a {
  text-align: center;
  display: block;
}

.Button_button--type--phone__2qRd3 {
  color: #fff;
  background-color: rgb(31, 58, 108);
}

.Button_button__2Lf63 {
  position: relative;
  /* display: inline-block;
    color: #000; */
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  padding: 1.15625rem 1.875rem;
  min-width: 14.25rem;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
}

.CarDetail_car-detail__ctas__1RC-8 button {
  display: block;
  width: 100%;
  margin: 1rem auto;
}

.Button_button--type--primary__Xr4ad {
  background-color: #ef6744;
}

.Button_button__2Lf63 {
  position: relative;
  display: inline-block;
  /* color: #000; */
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  padding: 1.15625rem 1.875rem;
  min-width: 14.25rem;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
}

.CarDetail_car-detail__ctas__1RC-8 a {
  text-align: center;
  display: block;
}

.Button_button--type--rci__1fwcC {
  background-color: #e36b3f;
  color: #fff;
}

.Button_button__2Lf63 {
  position: relative;
  /* display: inline-block;
    color: #000; */
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  padding: 1.15625rem 1.875rem;
  min-width: 14.25rem;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
}

.CarDetail_car-detail__name__SvoDt:after {
  content: "";
  top: 53%;
  left: 0;
  width: 0.25rem;
  height: 75%;
  background-color: #ef6744;
}

.CarDetail_car-detail__name__SvoDt:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Button_button__2Lf63:before {
  opacity: 0.2;
  transform: translate(-100%);
  background-color: rgba(0, 0, 0, 0.5);
}

.Button_button__2Lf63:after,
.Button_button__2Lf63:before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.Button_button__text__1ovH1 {
  color: #fff;
  font-family: "Montserrat" !important;
  text-transform: none;
}

.Button_button--type--phone__2qRd3:after {
  background-color: rgb(31, 58, 108);
}

.Button_button__2Lf63:after {
  transform: translate(-200%);
}

.Button_button__2Lf63:after,
.Button_button__2Lf63:before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.Button_button__2Lf63:before {
  opacity: 0.2;
  transform: translate(-100%);
  background-color: rgba(0, 0, 0, 0.5);
}

.Button_button__2Lf63:before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.Button_button--type--primary__Xr4ad:after {
  background-color: #ffde00;
}

.Button_button__2Lf63:after {
  transform: translate(-200%);
}

.Button_button__2Lf63:after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@media only screen and (max-width: 1080px) {
  .CarDetail_car-detail__3qqA_ {
    padding: 1.25rem 0.625rem;
  }
  .CarDetail_car-detail__adresse__2Bpuy {
    padding: 0;
    margin-bottom: 0.625rem;
    line-height: 1.3;
  }
}

.CarInfos_car-infos__2O9vf {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  margin: 1.25rem 0;
  background-color: #fafafa;
}

.CarInfos_car-infos__list__1CVoz {
  padding: 2.1875rem;
  display: table;
  margin: 0 auto;
}

.CarInfos_car-infos__item__2lMbv:not(:last-child) {
  margin-right: 3.75rem;
}

.CarInfos_car-infos__item__2lMbv {
  display: inline-block;
  position: relative;
}

.CarInfos_car-infos__title__11tvx {
  font-size: 18px;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0.625rem;
}

.CarInfos_car-infos__text___Lj_V {
  font-size: 16px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.CarInfos_car-infos__text___Lj_V img {
  vertical-align: top;
  margin-right: 0.3125rem;
}

.Button_button--type--primary__Xr4ad {
  background-color: #fc3;
}

.Button_button__2Lf63 {
  position: relative;
  display: inline-block;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  padding: 1.15625rem 1.875rem;
  min-width: 14.25rem;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
}

.Button_button__2Lf63:before {
  opacity: 0.2;
  transform: translate(-100%);
  background-color: rgba(0, 0, 0, 0.5);
}

.Button_button__2Lf63:after,
.Button_button__2Lf63:before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.Button_button--type--primary__Xr4ad:after {
  background-color: #ffde00;
}

.Button_button__2Lf63:after {
  transform: translate(-200%);
}

.Button_button__2Lf63:after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.0625rem;
  width: calc(100% + 1px);
  height: 100%;
  z-index: 0;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.PersonnelInfosForm {
  text-align: center;
  margin-top: 2.5rem;
  border: 1px solid #dfdfdf;
  padding: 2.5rem 5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  color: #57576b;
}

.PersonnelInfosForm h2 {
  font-size: 28px;
  font-size: 1.75rem;
  max-width: 34.375rem;
  margin: 0 auto;
  line-height: 1.1;
}

.PersonnelInfosForm .el-row {
  margin-top: 1.75rem;
}

.el-row:after,
.el-row:before {
  display: table;
  content: "";
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #ef6744 !important;
  border-color: #ef6744 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ef6744 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ef6744 !important;
}
.idmmap {
  width: 65%;
  height: 600px;
}
@media only screen and (max-width: 600px) {
  .map-dirc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
  }
  .idmmap {
    display: none;
    visibility: hidden;
  }
  .datamapc {
    margin-left: 0rem;
    width: 90%;
  }
}

.pagination ul li {
  background: #ed5129 none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  list-style: outside none none;
  margin: 0 2px;
  text-align: center;
  vertical-align: middle;
  width: 32px;
}

.pagination ul li:hover {
  background: #ed5129 none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  list-style: outside none none;
  margin: 0 2px;
  text-align: center;
  vertical-align: middle;
  width: 32px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: white;
  background: white !important;
  color: #000 !important;
}

.navbar-expand-lg .navbar-nav > li > a {
  font-family: "Montserrat", sans-serif !important;
}

.btn {
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.form-label {
  font-family: "Montserrat", sans-serif !important;
}

.about_info h5 {
  font-family: "Montserrat", sans-serif !important;
}

p {
  font-family: "Montserrat", sans-serif !important;
}

.ant-steps-item-content,
div,
a,
label,
select,
summary,
textarea,
ul,
li,
span,
.label_icon,
h6,
.sttylMap,
.featured-pric,
h3,
h1,
h2,
h4 {
  font-family: "Montserrat", sans-serif !important;
}

.uppercase_text {
  font-size: 13px !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.resqrrd {
  color: red;
  margin-right: 1%;
  margin-left: 3%;
}

.img-fluid-VB {
  height: 140px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-btn-nav {
  color: #ef6744 !important;
  background-color: white !important;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.slick-next:before {
  color: #ef6744 !important;
}

.slick-prev:before {
  color: #ef6744 !important;
}

.slick-dots li.slick-active button:before {
  color: #ef6744 !important;
}

.slick-next:before {
  margin-left: 100% !important;
}

.compare_item {
  background: transparent !important;
}

.featured-car-list {
  min-height: 410px !important;
  display: block !important;
}

.bg-inc-g-blue {
  background-color: #1f2532 !important;
}

.grid_listing .product-listing-m {
  min-height: 450px !important;
}

.txthight {
  height: 44px !important;
}

.featured-car-content li {
  color: #ef6744 !important;
}

.grid_listing .product-listing-content ul.features_list li {
  color: #ef6744 !important;
}

#fun-facts {
  margin-bottom: 2% !important;
}

.ant-select-selection-placeholder {
  margin-top: 2.5% !important;
}

.txthight2 {
  height: 65px !important;
}

.ant-slider-rail {
  /* background-color: #ef6744!important; */
}

.ant-slider:hover .ant-slider-track {
  background-color: #ef6744 !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ef6744 !important;
}

.ant-slider-handle {
  border-color: #ef6744 !important;
}

.ant-slider-track {
  background-color: #ef6744 !important;
}

.ant-ribbon {
  font-size: 16px !important;
  height: 32px !important;
  line-height: 32px !important;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
}

.banner_text {
  /* margin-top: 20% !important; */
}

.btn-hd {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.footer-top ul li {
  color: white !important;
}

.image-gallery-slide .image-gallery-image {
  width: 100% !important;
  object-fit: contain !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  height: 600px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 85px !important;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: pointer !important;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: pointer !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  margin: auto !important;
  text-align: left !important;
}

.ant-tooltip {
  z-index: 1 !important;
}

.ant-input:placeholder-shown {
  text-align: left !important;
}

.ant-input {
  text-align: left !important;
}

.page-header_wrap {
  text-align: left !important;
}

.psa-fiche-vo-infovo-place {
  background: #f7f7f7 !important;
}

.psa-fiche-vo-infovo-place {
  padding: 20px 15px !important;
  margin: 15px !important;
}

.psa-fiche-vo-infovo-padding {
  padding: 0px 15px !important;
  overflow: hidden !important;
}

.no-margin {
  margin: 0 !important;
}

.psa-fiche-vo-infovo-place-container .psa-fiche-vo-infovo-pos {
  display: flex !important;
}

.psa-fiche-vo-infovo-pos-icon {
  text-align: center !important;
  padding-right: 7px !important;
}

.psa-fiche-vo-infovo-pos .agency-name {
  color: #63656a;
  font-size: 1rem !important;
  text-transform: uppercase !important;
}

.psa-fiche-vo-infovo-place .agency-tel {
  color: #868689 !important;
  display: inline-flex !important;
  align-items: center !important;
}

.psa-fiche-vo-infovo-place .gtm-vo-page-phone-number {
  margin-top: 5px !important;
}

.desktop-phone-number {
  word-break: break-all !important;
}

.psa-fiche-vo-infovo-place .psa-fiche-vo-infovo-dealer .gtm-vo-dealer-locator {
  display: flex !important;
  padding: 0 !important;
  align-items: center !important;
  justify-content: flex-end !important;
  text-align: right !important;
}

.psa-fiche-vo-infovo-place .more {
  color: rgb(31, 58, 108) !important;
  font-size: 0.59rem !important;
}

.more {
  color: #13d0ca !important;
  text-transform: uppercase !important;
  font-size: 1.2rem;
  font-weight: bold !important;
}

.psa-fiche-vo-infovo-place
  .psa-fiche-vo-infovo-dealer
  .gtm-vo-dealer-locator
  .icon-right {
  position: inherit;
  right: auto;
  top: auto;
  padding-left: 7px;
}

.text-dealer-locator {
  font-size: 0.7rem !important;
}

@media only screen and (max-width: 600px) {
  .login_btn {
    margin-top: 2px !important;
  }
}

.pdv-title {
  background-color: #fff;
}

.pdv-title {
  margin-bottom: 1.5rem;
}

.pdv-title .welcome {
  color: #ef6744;
  margin: 0;
  font-size: 0.8em;
  padding-left: 20px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  display: block;
}

.pdv-title .pdv_marketing_name {
  color: #000;
  font-size: 1.7rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  padding-left: 20px;
  margin-top: 0;
}

.pdv-title .marque-rattachement-1 {
  padding-left: 20px;
  font-size: 1.4rem;
  display: block;
}

.pdv-image-about-container {
  padding-right: 0;
}

.pdv-image-about-container .pdv-img-container-main {
  text-align: center;
  padding-left: 15px;
}

.pdv-image-about-container .pdv-img-container-main .pdv-img-container {
  height: 440px;
  overflow: hidden;
}

.pdv-image-about-container .pdv-img-container-main .pdv-img-container .pdv_img {
  max-height: 100%;
}

.pdv-image-about-container .pdv_description {
  padding-left: 15px;
}

.pdv-image-about-container .pdv_description .pdv_about {
  margin-bottom: 40px;
}

.pdv-image-about-container .pdv_description .pdv-about-title {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0 0.83em 0;
}

.pdv-infos-map-container {
  padding-right: 30px;
}

.pdv-infos-map {
  background-color: #f7f7f7;
}

.pdv-infos-map-container .pdv-infos-map {
  min-height: 440px;
  padding: 0;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos {
  padding: 20px 10px;
}

.pdv-infos .icon-home,
.pdv-infos .icon-clock {
  font-size: 1.8rem;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos .pdv-address-container {
  min-height: 75px;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos .desktop-phone-number,
.pdv-infos-map-container .pdv-infos-map .pdv-infos .mobile-phone-number {
  width: 100%;
  padding: 20px 0;
}

.pdv-into-vo-info-pdv-adresse-tel-value {
  display: inline-flex;
  align-items: center;
}

.pdv-into-vo-info-pdv-adresse-tel-value,
.pdv-into-vo-info-pdv-adresse-fax-value {
  font-weight: bold;
}

.pdv-into-vo-info-pdv-adresse-tel-value {
  vertical-align: super;
}

.desktop-phone-number {
  word-break: break-all;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos .desktop-phone-number,
.pdv-infos-map-container .pdv-infos-map .pdv-infos .mobile-phone-number {
  width: 100%;
  padding: 20px 0;
}

.pdv-infos .icon-home,
.pdv-infos .icon-clock {
  font-size: 1.8rem;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos .morning-hour {
  text-align: center;
}

.pdv-infos-map-container .pdv-infos-map .pdv-infos .morning-hour,
.pdv-infos-map-container .pdv-infos-map .pdv-infos .afternoon-hour {
  text-align: center;
}

#pdv_map {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
  height: 111px;
  margin-bottom: 20px;
}

/* #pdv_map div:first{
    height: 111px;
} */
.fifnctxt {
  color: black;
  font-size: 20px !important;
  font-family: Montserrat;
  line-height: 2.2;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.titleBlockItem {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #ef6744;
  text-transform: uppercase;
  margin-top: 40px;
}

.displayFlexFirst {
  display: flex;
}

.listItemData {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #595959;
}

.bJcrer {
  -webkit-box-flex: 1;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
}

.hknQho svg {
  width: 400px;
}

.hknQho {
  height: 100%;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .bJcrer {
    max-width: 50%;
  }
}

.CDXA {
  border: 1px solid rgb(242, 242, 242);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin: 1%;
}

#S input[type="checkbox"] {
  display: none;
}

#S label :checked + span {
  background: blue;
  color: white;
}

#S label span {
  display: inline-block;
  background: lightgrey;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
}

.dark-overlay {
  background: rgb(31, 58, 108) none repeat scroll 0 0 !important;
}

.ant-popover-placement-bottom {
  width: 300px !important;
}

.my-project {
  font-family: Montserrat;
  border-color: #cdcdcd;
  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  background-color: #fff;
  color: #323e48;
  text-align: left;
  letter-spacing: initial;
}

.myproject-header.visible-xs {
  display: flex !important;
  align-items: center;
}

.myproject-header.visible-xs {
  padding: 20px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
}

.myproject-header {
  font-size: 1.3em;
}

.my-project p {
  letter-spacing: initial;
}

.myproject-header.visible-xs .icon-user {
  font-size: 3.5rem;
  padding-bottom: 0.5rem;
  padding-right: 10px;
}

.myproject-header.visible-xs .myproject-close {
  background-color: transparent;
  border: 0 none;
  top: 2.6rem;
  right: 20px;
  position: absolute;
  width: 2em;
  height: 2em;
}

.my-project .is-disconnected {
  padding: 10px 10px 0 10px;
}

.my-project .is-disconnected .connexion {
  margin: 10px auto;
  width: 95%;
}

.my-project .connexion {
  padding: 30px 20px;
}

.my-project .is-disconnected .connexion .left .hello {
  font-size: 1.4em;
  font-weight: bold;
}

.my-project .is-disconnected .connexion .left .connect {
  color: #323e48;
  text-decoration: underline;
}

.my-project .connect {
  width: 95%;
  margin: 0 auto;
  padding: 10px 0 0 0;
}

.my-project .is-disconnected .connexion .right {
  padding-top: 6px;
}

.my-project .connexion .right {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .my-project {
    width: 555px;
  }
}

.faq {
  font-size: 14px;
  font-family: Montserrat;
  color: black;
  font-weight: bold;
}

.faqD {
  font-size: 12px;
  font-family: Montserrat;
  color: black;
  text-transform: initial;
}

.whoAreWe {
  font-size: 22px;
  font-family: Montserrat;
  color: black;
  font-weight: bold;
  text-transform: initial;
}

.whoAreWeD {
  font-size: 16px;
  font-family: Montserrat;
  color: black;
  text-transform: initial;
}

.whoAreWeD ul li {
  font-size: 16px;
  font-family: Montserrat;
  color: black;
  text-transform: initial;
}

.uppr {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

li {
  text-transform: uppercase;
}

.sslccpdv {
  border: 2px solid #ef6744;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}

.ant-descriptions ant-descriptions-bordered {
  width: 69%;
  margin: 0 auto;
  border-right: 1px solid red;
}

.ant-descriptions-title {
  text-align: center;
}

.ant-descriptions-view {
  width: 66%;
  overflow: hidden;
  border-radius: 2px;
  margin: 0 auto;
}

.img-modal {
  margin: 0 auto 21px;
  display: flex;
}

.guide-achat h3 {
  color: #ed5129;
}

.guide-achat ul li {
  text-transform: inherit;
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
}

.guide-achat h1 strong span,
.guide-achat h2 strong span,
.guide-achat h3 strong span,
.guide-achat h4 strong span,
.guide-achat h5 strong span,
.guide-achat h6 strong span {
  font-weight: bold;
}

.PersonnelInfosForm .ant-checkbox-wrapper {
  width: max-content;
}

.simule .simule-butt {
  flex: 1;
}

.simule .ant-col-offset-12 {
  margin-left: 0;
}

.simule .ant-form-item-control-input {
  margin: auto;
}

.cardDetail {
  padding: 0 0.5rem;
}

.cardDetail .row .col-lg-7 {
  flex: 0 0 54%;
  max-width: 54%;
  padding-right: 5px;
  padding-left: 5px;
}

.cardDetail .row .col-lg-5 {
  flex: 0 0 46%;
  max-width: 46%;
  padding-right: 5px;
  padding-left: 5px;
}

/* update */

/* hheightAuto for img */

.heightAuto img {
  height: 205px !important;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 992px) {
  .cardDetail .row .col-lg-5,
  .cardDetail .row .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.revendrePopUp tr td .ant-descriptions-item-container {
  flex-wrap: wrap;
}

.modal-viewDetail p {
  margin-bottom: 0px;
}

.revendrePopUp .ant-descriptions-view {
  width: 90%;
}

.displayInDesktop {
  display: block !important;
}

.displayInMobile {
  display: none !important;
}

.classCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem;
}

@media only screen and (max-width: 777px) {
  .PersonnelInfosForm {
    padding: 20px 2%;
  }
  .displayInDesktop {
    display: none !important;
  }
  .displayInMobile {
    display: block !important;
  }
  .fifnctxt {
    font-size: 16px !important;
  }
  .classCenter {
    align-items: normal;
    justify-content: normal;
    margin: 13px auto;
    width: 90%;
  }
  .PersonnelInfosForm .ant-checkbox-wrapper {
    width: auto;
  }

  .navbar-expand-lg .navbar-nav > li > a {
    line-height: 21px;
    padding: 0px 22px;
  }

  .cstmTite {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .ant-card-head-title {
    white-space: normal;
  }
  .cstmRow {
    width: 100%;
  }
  .txthight {
    height: 90px !important;
  }
  .price_info {
    height: 69px;
    display: flex;
    justify-content: space-between;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal {
    width: 96% !important;
    flex-direction: column;
  }
  .ant-steps-item-title {
    white-space: pre-wrap;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0px;
    white-space: pre-wrap;
    margin-top: 14px;
  }
}

@media (max-width: 880px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    width: 96% !important;
    flex-direction: column;
  }
  .ant-steps-item-title {
    white-space: pre-wrap;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0px;
    white-space: pre-wrap;
    margin-top: 14px;
  }
}
