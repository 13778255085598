body,
html {
    width: 100%;
    height: 100%;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 14px;
    font-weight: 500;
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    font-feature-settings: "tnum", "tnum"
}

* {
    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #f0f2f5;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f0f2f5;
}

::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 8px;
    border: 2px solid #f0f2f5;
}

.MessageForm {
    margin-left: 10px;
}

.MessageBox {
    float: left;
    width: 98%;
    margin: 5px 0 0 2%;
}

.MessageBox .ChatMessage {
    width: 80%;
    min-height: 40px;
}

.MessageBox .ChatMessage .RightBubble {
    position: relative;
    background: rgb(31, 58, 108);
    border-top-left-radius: .4em;
    border-bottom-left-radius: .4em;
    border-bottom-right-radius: .4em;
    padding: 5px 10px 10px;
    left: 15%;
    color: white;

}

.MessageBox .ChatMessage .RightBubble span.MsgName {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.MessageBox .ChatMessage .RightBubble span.MsgDate {
    font-size: 10px;
}

.MessageBox .ChatMessage .RightBubble:after {
    content: '';
    position: absolute;
    right: 0;
    top: 13px;
    width: 0;
    height: 0;
    border: 27px solid transparent;
    border-left-color: rgb(31, 58, 108);
    border-right: 0;
    border-top: 0;
    margin-top: -13.5px;
    margin-right: -27px;
}

.MessageBox .ChatMessage .LeftBubble {
    position: relative;
    background: rgb(31, 58, 108);
    border-top-right-radius: .4em;
    border-bottom-left-radius: .4em;
    border-bottom-right-radius: .4em;
    padding: 5px 10px 10px;
    left: 5%;
}

.MessageBox .ChatMessage .LeftBubble span.MsgName {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.MessageBox .ChatMessage .LeftBubble span.MsgDate {
    font-size: 10px;
    color: white;

}

.MessageBox .ChatMessage .LeftBubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 13px;
    width: 0;
    height: 0;
    border: 27px solid transparent;
    border-right-color: lightblue;
    border-left: 0;
    border-top: 0;
    margin-top: -13.5px;
    margin-left: -27px;
}

.MessageBox .ChatStatus {
    min-height: 49px;
}

.MessageBox .ChatStatus .ChatDate {
    display: block;
    font-size: 10px;
    font-style: italic;
    color: #777;
    height: 15px;
    left: 10%;
    right: 10%;
}

.MessageBox .ChatStatus .ChatContentCenter {
    padding: 5px 10px;
    background-color: #e1e1f7;
    border-radius: 6px;
    font-size: 12px;
    color: #555;
    height: 34px;
    left: 10%;
    right: 10%;
}

.scroll-style {
    overflow-y: scroll;
    height: 200px;
}
